<template>
  <div>
    <the-breadcrumbs :custom-breadcrumbs="breadcrumbs"></the-breadcrumbs>
    <page-content page-title="Neue Einrichtung">
      <institution-form
        :institution="institution"
        submit-label="Neue Einrichtung anlegen"
        :form-submitted="saveInstitution"
        mode="new"
      ></institution-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import InstitutionForm from "@/components/forms/InstitutionForm"
import InstitutionsRepository from "@/repositories/institutions_repository.js"

export default {
  metaInfo: {
    title: "Neue Einrichtung"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    InstitutionForm
  },
  data() {
    return {
      institution: {
        description: ""
      }
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          path: "/guide",
          text: "Wissenswertes"
        },
        {
          path: `/institution_collections/${this.$route.query.institution_collection_id}`,
          text: `${this.$route.query.institution_collection_name}`
        }
      ]
    }
  },
  async created() {
    this.institution.collection_ids = [
      this.$route.query.institution_collection_id
    ]
  },
  methods: {
    async saveInstitution(institution) {
      await InstitutionsRepository.create(institution)
      try {
        await this.$router.push({
          name: "GuidePage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    }
  }
}
</script>
